import { useState, type FC, type HTMLAttributes, type ReactNode } from "react";
import {
  Text,
  type FontSizeType,
  IconFa,
  Box,
} from "@cruk/cruk-react-components";
import { faBullhorn, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { camelCaseToCapitalisedCase } from "@fwa/src/utils/formatUtils";
import { EditableTextField } from "@fwa/src/components/EditableTextField";
import { Editable } from "@fwa/src/components/Editable";

import {
  ActivityIconWrapper,
  ActivityItem,
  LeftAlign,
  RowStretchFullWidthBreakPoint,
} from "@fwa/src/components/styles";

import { type FundraisingPageType, type ValidationType } from "@fwa/src/types";

type Props = {
  fieldName: string;
  label?: string;
  hintText?: string;
  text: string;
  validation: ValidationType;
  handleEditData: (
    data: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => Promise<void | FundraisingPageType>;
  textComponent?: FC<HTMLAttributes<HTMLElement>>;
  editButtonColor?: string;
  placeHolder?: ReactNode;
  textSize?: FontSizeType;
  fullWidth?: boolean;
};

const DefaultTextComponent = (props: HTMLAttributes<HTMLSpanElement>) => (
  <Text as="span" {...props} />
);

export const FundraisingPageActivityNameForm = ({
  fieldName,
  text,
  label,
  hintText,
  placeHolder,
  validation,
  handleEditData,
  editButtonColor,
  textSize = "m",
  textComponent,
  fullWidth,
}: Props) => {
  const [textState, setTextState] = useState<string>(text || "");
  const [validationMessage, setValidationMessage] = useState<string>("");
  const TextComponent = textComponent ?? DefaultTextComponent;

  const handleSubmit = () => handleEditData({ [`${fieldName}`]: textState });
  const isEmpty = !text?.length;
  const showPlaceholder = isEmpty && !!placeHolder;

  return (
    <Editable
      fullWidth={fullWidth}
      fadeOnHover={false}
      fieldName={fieldName}
      isValid={!validationMessage.length}
      editButtonHidden
      editNode={
        <ActivityItem $isEditing $canEdit>
          <ActivityIconWrapper $isEditing $isEmpty={isEmpty}>
            <IconFa faIcon={faBullhorn} size="100%" />
          </ActivityIconWrapper>
          <LeftAlign marginBottom="s">
            <EditableTextField
              label={label}
              text={text}
              hintText={hintText}
              setCurrentValue={(value) => {
                setTextState(value);
              }}
              validation={validation}
              validationMessage={validationMessage}
              setValidationMessage={setValidationMessage}
              textSize={textSize}
            />
          </LeftAlign>
        </ActivityItem>
      }
      viewNode={
        <ActivityItem $canEdit>
          <ActivityIconWrapper $isEmpty={isEmpty}>
            <IconFa faIcon={faBullhorn} size="100%" />
          </ActivityIconWrapper>
          {!showPlaceholder ? (
            <RowStretchFullWidthBreakPoint>
              <TextComponent>{text}</TextComponent>
              <Box as="span" marginLeft="xxs">
                <IconFa faIcon={faPenToSquare} />
              </Box>
            </RowStretchFullWidthBreakPoint>
          ) : (
            placeHolder
          )}
        </ActivityItem>
      }
      handleSubmit={handleSubmit}
      editButtonColor={editButtonColor}
      tooltip={`Edit ${camelCaseToCapitalisedCase(fieldName)}`}
    />
  );
};

export default FundraisingPageActivityNameForm;
