import { useState, type ChangeEvent } from "react";
import { Text, Checkbox, Box, DateField } from "@cruk/cruk-react-components";
import styled from "styled-components";
import { parseISO, addHours } from "date-fns";

import { isValidDate } from "@fwa/src/utils/timeUtils";
import { prefixSingleWithZero } from "@fwa/src/utils/formatUtils";

import { EditableTextField } from "@fwa/src/components/EditableTextField";
import { Editable } from "@fwa/src/components/Editable";
import { InMemory } from "@fwa/src/components/InMemory";
import { OnlyYou } from "@fwa/src/components/OnlyYou";

import { DashedBorder, ResponsiveRow } from "@fwa/src/components/styles";

import {
  type FundraisingPageType,
  type InMemoryDateType,
  type PageType,
} from "@fwa/src/types";

type Props = {
  page: FundraisingPageType;
  name?: string;
  birthString?: string;
  deathString?: string;
  handleEditData: (
    data: Partial<PageType>,
    refresh?: boolean,
  ) => Promise<void | PageType>;
  canEdit: boolean;
};

const placeHolder = (
  <Box>
    <DashedBorder>
      <OnlyYou />
      <Text>Are you raising money in memory of someone?</Text>
    </DashedBorder>
  </Box>
);

const DateFieldWrapper = styled(Box)`
  min-width: 16rem;
`;

const midDayDateFromISODateString = (isoDateString: string): Date =>
  addHours(parseISO(isoDateString), 12);

export const EditableInMemoryForm = ({
  page,
  name = "",
  birthString,
  deathString,
  handleEditData,
  canEdit,
}: Props) => {
  const [showDate, setShowDate] = useState<boolean>(
    !!birthString || !!deathString || false,
  );
  const [nameState, setNameState] = useState<string>(name);
  const [dateState, setDateState] = useState<InMemoryDateType>({
    birthDay: birthString
      ? midDayDateFromISODateString(birthString).getDate().toString()
      : null,
    birthMonth: birthString
      ? (midDayDateFromISODateString(birthString).getMonth() + 1).toString()
      : null,
    birthYear: birthString
      ? midDayDateFromISODateString(birthString).getFullYear().toString()
      : null,
    deathDay: deathString
      ? midDayDateFromISODateString(deathString).getDate().toString()
      : null,
    deathMonth: deathString
      ? (midDayDateFromISODateString(deathString).getMonth() + 1).toString()
      : null,
    deathYear: deathString
      ? midDayDateFromISODateString(deathString).getFullYear().toString()
      : null,
  });
  const [nameValidationMessage, setNameValidationMessage] =
    useState<string>("");
  const [birthValidationMessage, setBirthValidationMessage] =
    useState<string>("");
  const [deathValidationMessage, setDeathValidationMessage] =
    useState<string>("");

  const handleSubmit = () => {
    const { birthISOString, deathISOString } = inMemoryStateDateToIsoDates();
    const dateOfBirthDeceased = showDate ? birthISOString : null;
    const dateOfDeathDeceased = showDate ? deathISOString : null;

    return handleEditData({
      activityInMemory: nameState,
      dateOfBirthDeceased,
      dateOfDeathDeceased,
    });
  };

  const getDatesFromState = () => {
    const birthDatePartial = `${dateState.birthYear || ""}${
      dateState.birthMonth || ""
    }${dateState.birthDay || ""}`;
    const deathDatePartial = `${dateState.deathYear || ""}${
      dateState.deathMonth || ""
    }${dateState.deathDay || ""}`;
    const birthDate = parseISO(
      `${dateState.birthYear || ""}-${prefixSingleWithZero(
        dateState.birthMonth || "",
      )}-${prefixSingleWithZero(dateState.birthDay || "")}`,
    );
    birthDate.setHours(12);
    const deathDate = parseISO(
      `${dateState.deathYear || ""}-${prefixSingleWithZero(
        dateState.deathMonth || "",
      )}-${prefixSingleWithZero(dateState.deathDay || "")}`,
    );
    deathDate.setHours(12);
    const birthValid = isValidDate(birthDate);
    const deathValid = isValidDate(deathDate);
    return {
      birthDate,
      deathDate,
      birthValid,
      deathValid,
      birthDatePartial,
      deathDatePartial,
    };
  };

  // We need to submit dates as yyyy-mm-dd
  // so we need to take the days the months and the years figure out if they make valid dates
  // and then convert them to the ISO string format
  const inMemoryStateDateToIsoDates = () => {
    const { birthDate, deathDate, birthValid, deathValid } =
      getDatesFromState();
    return {
      birthISOString:
        birthDate && birthValid ? birthDate.toISOString().split("T")[0] : null,
      deathISOString:
        deathDate && deathValid ? deathDate.toISOString().split("T")[0] : null,
    };
  };

  // hides/shows the dates
  const handleShowDatesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowDate(e.target.checked);
  };

  // updates date state when there is a change in any date component
  const handeDateChanged = (e: ChangeEvent<HTMLElement>) => {
    const input = e.target as HTMLInputElement;
    setDateState({ ...dateState, [`${input.name}`]: input.value });
  };

  // validate birth on blur
  const handleBirthDateBlur = () => {
    const now = new Date();
    const {
      birthDate,
      deathDate,
      birthValid,
      deathValid,
      birthDatePartial,
      deathDatePartial,
    } = getDatesFromState();
    if (birthDatePartial.length && !birthValid) {
      setBirthValidationMessage("Please enter a valid date with DD MM YYYY");
    } else if (birthDate.getTime() > now.getTime()) {
      setBirthValidationMessage("All dates must be in the past");
    } else if (birthDate.getTime() < parseISO("1753-01-01").getTime()) {
      setDeathValidationMessage("All dates must be after the year 1752");
    } else if (deathValid && birthDate > deathDate) {
      setDeathValidationMessage(
        "The date of birth must be the same as or before the date of passing",
      );
    } else if (
      (deathValid && birthDate < deathDate) ||
      (birthValid && !deathDatePartial.length)
    ) {
      setDeathValidationMessage("");
      setBirthValidationMessage("");
    } else {
      setBirthValidationMessage("");
    }
  };

  // validate death on blur
  const handleDeathDateBlur = () => {
    const now = new Date();
    const { birthDate, deathDate, birthValid, deathValid, deathDatePartial } =
      getDatesFromState();
    if (deathDatePartial.length && !deathValid) {
      setDeathValidationMessage("Please enter a valid date with DD MM YYYY");
    } else if (deathDate.getTime() > now.getTime()) {
      setDeathValidationMessage("All dates must be in the past");
    } else if (
      deathDate.getTime() < parseISO("1753-01-01").getTime() ||
      (birthDate.getTime() &&
        birthDate.getTime() < parseISO("1753-01-01").getTime())
    ) {
      setDeathValidationMessage("All dates must be after the year 1752");
    } else if (birthValid && birthDate > deathDate) {
      setDeathValidationMessage(
        "The date of birth must be the same as or before the date of passing",
      );
    } else {
      setDeathValidationMessage("");
    }
  };

  // we don't want to show the error message
  // when people are still hopping from date to month to year
  const handleBirthDateFocus = () => {
    setBirthValidationMessage("");
  };
  const handleDeathDateFocus = () => {
    setDeathValidationMessage("");
  };

  return canEdit ? (
    <div data-component="page-in-memory-form">
      <Editable
        fieldName="inMemory"
        isValid={
          !nameValidationMessage.length &&
          !birthValidationMessage.length &&
          !deathValidationMessage.length
        }
        editNode={
          <>
            <Text>In memory of</Text>
            <EditableTextField
              aria-label="in memory name"
              text={name}
              setCurrentValue={(value) => {
                setNameState(value);
              }}
              validation={{
                type: "textField",
                maxLength: 100,
              }}
              validationMessage={nameValidationMessage}
              setValidationMessage={setNameValidationMessage}
            />
            <Box>
              <Checkbox onChange={handleShowDatesChange} checked={showDate}>
                Add memorial dates
              </Checkbox>
            </Box>
            {showDate && (
              <Box>
                <ResponsiveRow>
                  <DateFieldWrapper>
                    <DateField
                      dayName="birthDay"
                      monthName="birthMonth"
                      yearName="birthYear"
                      day={dateState.birthDay ?? ""}
                      month={dateState.birthMonth ?? ""}
                      year={dateState.birthYear ?? ""}
                      label="When were they born?"
                      onChange={handeDateChanged}
                      onBlur={handleBirthDateBlur}
                      onFocus={handleBirthDateFocus}
                      errorMessage={birthValidationMessage}
                    />
                  </DateFieldWrapper>

                  <DateFieldWrapper>
                    <DateField
                      dayName="deathDay"
                      monthName="deathMonth"
                      yearName="deathYear"
                      day={dateState.deathDay ?? ""}
                      month={dateState.deathMonth ?? ""}
                      year={dateState.deathYear ?? ""}
                      label="When did they pass away?"
                      onChange={handeDateChanged}
                      onBlur={handleDeathDateBlur}
                      onFocus={handleDeathDateFocus}
                      errorMessage={deathValidationMessage}
                    />
                  </DateFieldWrapper>
                </ResponsiveRow>
              </Box>
            )}
          </>
        }
        viewNode={
          !name?.length && placeHolder ? (
            // I want to return JSX type not react node
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>{placeHolder}</>
          ) : (
            <InMemory
              name={nameState}
              birthDateString={birthString || undefined}
              deathDateString={deathString || undefined}
            />
          )
        }
        handleSubmit={handleSubmit}
        tooltip="Edit in memory details"
      />
    </div>
  ) : page.activityInMemory ? (
    <Box>
      <InMemory
        name={page.activityInMemory}
        birthDateString={page.dateOfBirthDeceased}
        deathDateString={page.dateOfDeathDeceased}
      />
    </Box>
  ) : null;
};

export default EditableInMemoryForm;
