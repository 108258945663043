import { useState, type FC, type HTMLAttributes, type ReactNode } from "react";
import { Box, IconFa } from "@cruk/cruk-react-components";
import { formatInTimeZone } from "date-fns-tz";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

import { isValidDate } from "@fwa/src/utils/timeUtils";
import { camelCaseToCapitalisedCase } from "@fwa/src/utils/formatUtils";
import {
  DateRangePicker,
  type StartAndEndDateType,
} from "@fwa/src/components/DateRangePicker";
import { Editable } from "@fwa/src/components/Editable";

import {
  ActivityIconWrapper,
  ActivityItem,
  LeftAlign,
  RowStretchFullWidthBreakPoint,
} from "@fwa/src/components/styles";
import { type FundraisingPageType } from "@fwa/src/types";

type Props = {
  fieldName?: string;
  textComponent: FC<HTMLAttributes<HTMLElement>>;
  handleEditData: (
    data: Partial<FundraisingPageType>,
    refresh?: boolean,
  ) => Promise<void | FundraisingPageType>;
  editButtonColor?: string;
  startDate: string | null;
  endDate: string | null;
  startDateLabel?: string;
  endDateLabel?: string;
  startDateFieldName: string;
  endDateFieldName: string;
  placeHolder?: ReactNode;
  fullWidth?: boolean;
  allowNullDate?: boolean;
};

export const FundraisingPageActivityDateForm = ({
  startDate,
  endDate,
  startDateLabel = "Start date",
  endDateLabel = "End date",
  startDateFieldName,
  endDateFieldName,
  textComponent,
  handleEditData,
  editButtonColor,
  fullWidth,
  placeHolder = null,
  allowNullDate,
  fieldName,
}: Props) => {
  const [dateData, setDateData] = useState<StartAndEndDateType>({
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
  });
  const [isDateRangeValid, setIsDateRangeValid] = useState<boolean>(true);

  const startDateString =
    dateData?.startDate && isValidDate(dateData?.startDate)
      ? formatInTimeZone(dateData.startDate, "Europe/London", "EEE d LLL y")
      : null;

  const endDateString =
    dateData?.endDate && isValidDate(dateData?.endDate)
      ? formatInTimeZone(dateData.endDate, "Europe/London", "EEE d LLL y")
      : null;

  const activityDateTimeEndString =
    endDateString && endDateString !== startDateString
      ? ` - ${endDateString}`
      : "";

  const combinedDateString = `${startDateString || ""}${
    activityDateTimeEndString || ""
  }`;

  const TextComponent = textComponent;

  const handleSubmit = async () => {
    // because of generic error in editable component you will only see this in dev tools
    // if (!dateData || !Object.keys(dateData).length) {
    //   return undefined;
    // }
    // The server uses timestamp format so we need to convert date objects
    // Example activityDate:  "2020-12-20T11:51:28+00:00" looks like toISOString() but isn't quite

    const transformedData = {
      activityDateTime: dateData?.startDate
        ? `${dateData.startDate.toISOString().split(".")[0]}+00:00`
        : null,
      activityDateTimeEnd:
        dateData?.endDate && dateData?.startDate
          ? `${dateData.endDate.toISOString().split(".")[0]}+00:00`
          : dateData.startDate
            ? `${dateData.startDate.toISOString().split(".")[0]}+00:00`
            : null,
    };

    await handleEditData(transformedData);
    return undefined;
  };

  const handleCancel = () => {
    setDateData({
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
    });
  };

  return (
    <Editable
      fieldName={fieldName}
      isValid={isDateRangeValid}
      fullWidth={fullWidth}
      fadeOnHover={false}
      editButtonHidden
      editNode={
        <ActivityItem $isEditing $canEdit>
          <ActivityIconWrapper $isEditing $isEmpty={!startDateString}>
            <IconFa faIcon={faCalendarDays} size="100%" />
          </ActivityIconWrapper>
          <LeftAlign>
            <DateRangePicker
              allowNullDate={allowNullDate}
              startDate={dateData.startDate || undefined}
              endDate={dateData.endDate || undefined}
              startDateLabel={startDateLabel}
              endDateLabel={endDateLabel}
              setCurrentValue={(selection) => {
                setDateData(selection as StartAndEndDateType);
              }}
              setIsDateRangeValid={setIsDateRangeValid}
              minDate={
                new Date(new Date().setFullYear(new Date().getFullYear() - 10))
              }
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              }
            />
          </LeftAlign>
        </ActivityItem>
      }
      viewNode={
        <ActivityItem $canEdit>
          <ActivityIconWrapper $isEmpty={!startDateString}>
            <IconFa faIcon={faCalendarDays} size="100%" />
          </ActivityIconWrapper>
          {startDateString ? (
            <RowStretchFullWidthBreakPoint>
              <TextComponent>{combinedDateString}</TextComponent>
              <Box as="span" marginLeft="xxs">
                <IconFa faIcon={faPenToSquare} />
              </Box>
            </RowStretchFullWidthBreakPoint>
          ) : (
            placeHolder
          )}
        </ActivityItem>
      }
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      editButtonColor={editButtonColor}
      tooltip={`Edit ${camelCaseToCapitalisedCase(
        startDateFieldName,
      )} and ${camelCaseToCapitalisedCase(endDateFieldName)}`}
    />
  );
};

export default FundraisingPageActivityDateForm;
