import styled from "styled-components";
import {
  TextField,
  type ThemeType,
  type FontSizeType,
} from "@cruk/cruk-react-components";

export const DisabledTextBorder = styled.div<{ theme: ThemeType }>`
  background-color: ${({ theme }) => theme.tokenColors.grey_200};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
  > * {
    min-height: ${({ theme }) => theme.spacing.s};
    padding: ${({ theme }) => theme.spacing.xs};
  }
`;

export const TextFieldStyled = styled(TextField)<{
  theme: ThemeType;
  $textSize: FontSizeType;
}>`
  font-size: ${({ theme, $textSize }) =>
    !!$textSize && !!theme?.fontSizes[$textSize]
      ? theme?.fontSizes[$textSize]
      : theme.fontSizes.m};
`;
